<template>
  <v-autocomplete
  v-model="model"
  :label="label"
  :items="items"
  :item-text="itemText"
  :item-value="itemValue"
  :return-object="!itemValue"/>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: null
    },
    label: String,
    items: {
      type: Array,
      default: []
    },
    itemText: {
      type: String,
      default: null
    },
    itemValue: {
      type: String,
      default: null
    },
  },
  model: {
    "prop": "value",
    "event": "valueChanged"
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  }
}
</script>

<style>

</style>