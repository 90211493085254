import http from './http'
import apiResultWrapper from './apiResultWrapper'
// add new user. Make first validation
const newUser = async (user) => apiResultWrapper.apiResult(await http.post('/api/v1/users', user))
// edit old user.
const editUser = async (user) => apiResultWrapper.apiResult(await http.put(`/api/v1/users/${user.id}`, user))
// edit self user.
const editSelf = async (user) => apiResultWrapper.apiResult(await http.put('/api/v1/users/self', user))
// lock user
const lockUser = async (id) => {}
// unlock user
const unlockUser = async (id) => {}
// change password
const changePassword = async (id) => {}
// profile of user
const userProfile = async (id) => {}
// list all user / pagination
const listUsers = async ({ page, size, sort }, { keyWord, lockedType }) => apiResultWrapper.apiResult(await http.get(`/api/v1/users?page=${page || 0}&size=${size || 25}${sort ? '&sort=' + sort : ''}${keyWord ? '&word=' + keyWord : ''}${lockedType ? '&lockedType=' + lockedType : ''}`))
// search user
const searchUser = async (search, { page, size }) => {}
// list roles
const listRoles = async () => apiResultWrapper.apiResult(await http.get('/api/v1/users/roles'))
// list permissions
const listPermissions = async () => apiResultWrapper.apiResult(await http.get('/api/v1/users/permissions'))
// list permissions for user
const listUserPermissions = async (userId) => apiResultWrapper.apiResult(await http.get(`/api/v1/users/${userId}/permissions`))
// get one user
const user = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/users/${id}`))
// get self user
const userSelf = async () => apiResultWrapper.apiResult(await http.get('/api/v1/users/self'))
// fetch user settings
const fetchSettings = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/users/${id}/settings`))
// save user blotter duty setting
const blotterDuty = async (id, enabled) => apiResultWrapper.apiResult(await http.post(`/api/v1/users/${id}/setting/blotter-duty/${enabled}`))
// list available managers
const listAvailableManager = async () => apiResultWrapper.apiResult(await http.get('/api/v1/users/available-managers'))
// list available employees
const listAvailableEmployees = async () => apiResultWrapper.apiResult(await http.get('/api/v1/users/available-employees'))
// get user org structure
const userOrganizationStructure = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/users/${id}/org-structure`))
// save user org structure
const saveUserOrganizationStructure = async (id, { directManager, manageByMe, changeDirectManager, changeManageByMe }) => apiResultWrapper.apiResult(await http.post(`/api/v1/users/${id}/org-structure`, { directManager, manageByMe, changeDirectManager, changeManageByMe }))
// add roles to user
// TODO
// add permission to user
// TODO
// remove roles from user
// TODO
// remove permissions from user
// TODO
// get one user
const fees = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/users/${id}/fees`))

const saveUserFee = async (id, fees) => apiResultWrapper.apiResult(await http.post(`/api/v1/users/${id}/fees`, fees))

export default {
  newUser,
  editUser,
  editSelf,
  lockUser,
  unlockUser,
  changePassword,
  userProfile,
  listUsers,
  searchUser,
  listRoles,
  listPermissions,
  listUserPermissions,
  user,
  userSelf,
  listAvailableManager,
  listAvailableEmployees,
  userOrganizationStructure,
  fetchSettings,
  blotterDuty,
  saveUserOrganizationStructure,
  fees,
  saveUserFee
}
