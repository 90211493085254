<template>
  <div>
    <!-- <div class="d-none">{{getDynamicKey}}</div> -->
    <v-data-table
    class="elevation-1 row-pointer"
    :loading="loadingProp"
    :disable="loadingProp"
    :loading-text="loadingLabel"
    :height="height"
    :headers="headers"
    :items="items"
    :server-items-length="totalElements"
    @update:items-per-page="updateSize"
    :items-per-page="itemsPerPage"
    :page.sync="pageProp"
    :footer-props="footerProps"
    @current-items="setCurrentItemsLabel"
    :item-key="itemKey"
    disable-filtering
    @update:sort-by="onSortByChanged"
    @update:sort-desc="onDescChanged"
    :show-expand="showExpand"
    :expanded.sync="expanded"
    :hide-default-footer="hideDefaultFooter"
    :class="`${tableId} registrations-data-table pa-4 secondaryBtn--text`"
    @click:row="item => $emit('click:row', item)"
    @dblclick:row="(MouseEvent, obj) => $emit('dblclick:row', MouseEvent, obj)">
      <template #top>
          <slot name="top"></slot>
      </template>
      <template #no-data>
          <slot name="no-data">
            <div
            class="d-flex justify-center align-center">
                <p class="secondaryBtn--text text-body-1">No Data</p>
            </div>
          </slot>
      </template>

      <template 
      v-for="(h, i) in headers" 
      v-slot:[`header.${h.value}`]="{ header }">
        <div 
        :key="i"
        class="secondaryBtn--text text-body-1">
          <slot
          :name="`header.${h.value}`"
          :header="header">
            <!-- default -->
            {{header.text}}
          </slot>
        </div>
      </template>

      <!-- The IsSpecified works when there is at least one overrided item! -->
      <template 
      v-for="(h, i) in headers.filter(h => overridedItems.includes(h.value))" 
      v-slot:[`item.${h.value}`]="{ item, index }">
        <div 
        :key="i"
        class="secondaryBtn--text text-body-1"
        :aria-selected="`${item.isSpecified ? 'selected' : ''}`">
          <slot
          :name="`item.${h.value}`"
          :item="item"
          :index="index">
             <!-- default -->
             {{item[h.value]}}
          </slot>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <slot 
        name="expanded-item"
        :headers="headers"
        :item="item">
        </slot>
      </template>
    </v-data-table>
    <v-row
    v-if="hideDefaultFooter"
    justify="end"
    class="mt-2">
      <v-col cols="11" class="text-right">
        <v-pagination
          v-model="pageProp"
          :length="totalPages"
          :total-visible="7"
          @input="onPaginationBtnClicked"
        ></v-pagination>
      </v-col>
      <v-col cols="1" class="text-right" align-self="end">
        <v-select
          class="pa-0 ma-0 mt-2"
          v-model="sizeProp"
          @change="updateSize"
          :items="footerProps.itemsPerPageOptions"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { v4 as uuidGenerate } from 'uuid'
export default {
  props: {
    loadingLabel: {
      type: String,
      default: 'Loading data...'
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: () => {
        return null
      }
    },
    headers: {
      type: Array,
      requried: true
    },
    overridedItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    items: {
      type: Array,
      requried: true
    },
    totalElements: {
      type: Number,
      requried: true
    },
    onUpdateSize: Function,
    onPreviousClick: Function,
    onNextClick: Function,
    itemsPerPage: {
      type: Number,
      default: () => {
        return 10
      }
    },
    page: {
      type: Number,
      default: () => {
        return 1
      }
    },
    totalPages: {
      tyep: Number,
      default: () => {
        return 1
      }
    },
    itemKey: {
      type: String,
      default: () => {
        return 'id'
      }
    },
    showExpand: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    hideDefaultFooter: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    sortFieldMap: {
      type: Object,
      default: null
    },
    urlQueries: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 50, 100]
      },
      tableId: null,
      expanded: [],
      langTokenEvent: null,
      selectedIntervalId: null,
      sort: {
        value: 'id',
        direction: 'ASC',
        timeoutId: null
      },
      PAGE_DEFAULT: 1,
      SIZE_DEFAULT: 50
    }
  },
  computed: {
    pageProp: {
      get: function() {
        return this.$props.page
      },
      set: function(v) {
        this.$emit('update:page', v)
      }
    },
    sizeProp: {
      get: function() {
        return this.$props.itemsPerPage
      },
      set: function(v) {
        this.$emit('update:itemsPerPage', v)
      }
    },
    loadingProp: {
      get: function() {
        return this.$props.loading
      },
      set: function(v) {
        this.$emit('update:loading', v)
      }
    }
    // getDynamicKey() {
    //   if (this.langTokenEvent && this.langTokenEvent.dynamic !== this.langTokenEvent.static) {
    //     DynamicNotifier.reset(this.langTokenEvent)
    //     this.setCurrentItemsLabel()
    //   }
    //   return 0
    // }
  },
  watch: {
    '$route.query': {
      handler(v) {
        if (this.urlQueries) this.onQueryChanged(v)
      },
      immediate: true,
      deep: true
    },
    'totalPages': {
      handler(pagesCount) {
        if (pagesCount < this.pageProp) {
          this.updateRouterQueries({
            page: this.PAGE_DEFAULT,
            size: this.sizeProp
          })
        }
      },
    },
    'pageProp': {
      handler(page) {
        if (page > this.totalPages) {
          this.updateRouterQueries({
            page: this.PAGE_DEFAULT,
            size: this.sizeProp
          })
        }
      },
    },
    'sizeProp': {
      handler(size) {
        if (!this.footerProps.itemsPerPageOptions.includes(size)) {
          this.updateRouterQueries({
            page: this.pageProp,
            size: this.SIZE_DEFAULT
          })
        }
      },
    }
  },
  methods: {
    async onQueryChanged({page, size}) {
      const pageInt = Number.parseInt(page)
      const sizeInt = Number.parseInt(size)
      const isCriteriaValid = !Number.isNaN(pageInt) && !Number.isNaN(sizeInt)
      if (!isCriteriaValid) {
        this.updateRouterQueries({
          page: Number.isNaN(pageInt) ? this.PAGE_DEFAULT : pageInt,
          size: Number.isNaN(sizeInt) ? this.SIZE_DEFAULT : sizeInt
        })
      }

      this.pageProp = pageInt
      this.sizeProp = sizeInt
      this.$emit('query-changed')
    },
    updateRouterQueries({page, size}) {
      if (!this.urlQueries) return
      this.$router.push({
        path: this.$route.path,
        query: {
          page: page,
          size: size
        }
      })
    },
    onPaginationBtnClicked(newPage) {
      this.$emit('pagination-btn-click', newPage)
      this.updateRouterQueries({
        page: newPage,
        size: this.sizeProp
      })
    },
    async updateSize(itemsPerPage) {
      this.sizeProp = itemsPerPage
      if (this.onUpdateSize) {
        try {
          this.loadingProp = true
          await this.onUpdateSize()
        }
        finally {
          this.loadingProp = false
        }
      }
      this.updateRouterQueries({
        page: this.pageProp,
        size: this.sizeProp
      })
    },
    setCurrentItemsLabel() {
      //do nothing
      // setTimeout(() => {
      //   if (document.querySelector(`.${this.tableId} .v-data-footer__pagination`) !== undefined && 
      //       document.querySelector(`.${this.tableId} .v-data-footer__pagination`) !== null) {

      //     document.querySelector(`.${this.tableId} .v-data-footer__select`)
      //     .childNodes[0]
      //     .textContent = this.$t('common.rowsPerPage')
        
      //     document.querySelector(`.${this.tableId} .v-data-footer__pagination`)
      //     .childNodes[0]
      //     .textContent = document.querySelector(`.${this.tableId} .v-data-footer__pagination`)
      //     .childNodes[0]
      //     .textContent
      //     .replace(/of|от/, this.$t('common.currentItemsLabel'))
      //   }
      // }, 100)
    },
    //TODO: to review this
    getTrTag(d) {
      if (d.tagName === 'TR') return d
      else return this.getTrTag(d.parentNode)
    },
    onSortByChanged(field) {
      this.sort.value = field
      this.emitSortChanged()
    },
    onDescChanged(v) {
      this.sort.direction = v ? 'DESC' : 'ASC'
      this.emitSortChanged()
    },
    emitSortChanged() {
      if (!!this.sort.timeoutId) return

      this.sort.timeoutId = setTimeout(() => {
        const sortFieldMapField = !!this.sortFieldMap ? this.sortFieldMap[this.sort.value] : null
        const value = !!sortFieldMapField ? sortFieldMapField : this.sort.value
        this.$emit('sort-by-changed', !!value ? `${value},${this.sort.direction}` : null)
        this.sort.timeoutId = null
      }, 60)
    }
  },
  mounted() {
    document.querySelector(`.${this.tableId} button[aria-label="Previous page"]`)?.addEventListener('click', async () => { 
        if (this.onPreviousClick) {
          try {
            this.loadingProp = true
            await this.onPreviousClick()
          }
          finally {
            this.loadingProp = false
          }
        }
      })
    document.querySelector(`.${this.tableId} button[aria-label="Next page"]`)?.addEventListener('click', async () => { 
        if (this.onNextClick) {
          try {
            this.loadingProp = true
            await this.onNextClick()
          }
          finally {
            this.loadingProp = false
          }
        }
      })

      // this.selectedIntervalId = setInterval(() => {
      //   if (this.items.length === 0) return

      //   const d = document.querySelector('div[aria-selected~=selected]')
      //   if (d !== null) {
      //     const rowTag = this.getTrTag(d)
      //     rowTag.setAttribute('class', d.getAttribute('class').concat(' blinking-for-attention'))
      //   }
      //   clearInterval(this.selectedIntervalId)
        
      // }, 100)
  },
  created() {
    this.tableId = `main-table-${uuidGenerate()}`
  },
  beforeDestroy() {
    // clearInterval(this.selectedIntervalId)
  }
}
</script>
<style>
/*
#################### These will be needed for the customization, if any! ####################


.registrations-data-table.v-data-table  {
  border: #DF9811 1px solid;
}
.registrations-data-table.theme--light.v-data-table {
  background: #2C2937;
}
.registrations-data-table .v-select__selection.v-select__selection--comma {
  color: #DF9811;
}
.registrations-data-table.v-icon.notranslate .mdi.mdi-menu-down.theme--light.primary--text {
  color: #DF9811;
}
.registrations-data-table .v-select > .v-input__control > .v-input__slot{
  color: #DF9811;
}
.registrations-data-table .v-btn--icon.v-size--default .v-icon {
  color: #DF9811;
}
.registrations-data-table.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
  border-color: #643e0a;
}

.registrations-data-table .v-data-table__wrapper::-webkit-scrollbar
{
  width: 10px;
}
.registrations-data-table .v-data-table__wrapper::-webkit-scrollbar-thumb
{
  background: radial-gradient(rgb(94, 94, 114), #2C2937);
  border-radius: 2px;
}
.registrations-data-table .v-data-table__wrapper::-webkit-scrollbar-track
{
  background: #2C2937;
}
*/

@keyframes blinking-for-attention-anim {
  0% {background-color: rgba(0, 0, 0, 0);}
  50% {background-color: #d3a247be;}
  100% {background-color: rgba(0, 0, 0, 0);}
}

.blinking-for-attention {
  animation: blinking-for-attention-anim 1s 4;
}
</style>