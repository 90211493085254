import axios from 'axios'

// Wrapper for http call. By default use axios, but in other case may be need other

const get = async (...args) => await axios.get(...args)

const post = async (...args) => await axios.post(...args)

const put = async (...args) => await axios.put(...args)

export default {
  get,
  post,
  put
}
