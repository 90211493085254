<template>
  <v-container
    id="user-list-container"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <v-col cols="9">
        <v-text-field
          v-model="config.search.criteria"
          :disabled="config.search.loading"
          label="Like search"
          filled
          clearable
          single-line
          hide-details
          v-on:keyup.enter="search()"
        />
      </v-col>
      <v-col
        cols="2"
        class="text-right"
      >
        <text-field-autocomplete
        label="Unlocked only"
        v-model="config.locked.model"
        :items="config.locked.types"
        item-text="text"
        item-value="value"/>
      </v-col>
      <v-col
        cols="1"
        class="d-flex justify-center"
      >
        <v-btn
          :loading="config.search.loading"
          :disabled="config.search.loading"
          color="primary"
          class="white--text btn-center mr-0"
          x-large
          @click="search()"
        >
          Search
          <v-icon
            dark
            right
          >
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <main-data-table
        ref="mainDataTableRef"
        :url-queries="config.pagination.urlQueries"
        :headers="config.headers"
        :items="users"
        :loading="config.search.loading"
        :sort-field-map="config.sortFieldMap"
        :items-per-page.sync="config.pagination.size"
        :page.sync="config.pagination.page"
        :total-pages="config.pagination.totalPages"
        :total-elements="config.pagination.totalElements"
        @sort-by-changed="onSort"
        @click:row="clickRow"
        @query-changed="fetchUsers"
        
        loadingLabel="Loading... Please wait"
        hide-default-footer>

        </main-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="auto" class="text-left">
        <v-btn
          color="primary"
          class="white--text btn-center"
          x-large
          target="_blank"
          @click="newUser()"
        >
          New User
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from '@/shared/users'
import TextFieldAutocomplete from '@/components/textFields/TextFieldAutocomplete'
import MainDataTable from '@/components/MainDataTable'

export default {
  name: 'UsersList',
  components: {
    TextFieldAutocomplete,
    MainDataTable
  },
  data: () => ({
    config: {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Username', value: 'username' },
        { text: 'Email', value: 'email' },
        { text: 'Locked', value: 'lockedString' }
      ],
      pagination: {
        page: 1,
        size: 50,
        totalPages: 1,
        sort: null,
        totalElements: 0,
        urlQueries: true
      },
      search: {
        criteria: '',
        loading: false
      },
      locked: {
        types: [{
          text: 'Locked',
          value: "LOCKED"
        }, {
          text: 'Unlocked',
          value: "UNLOCKED"
        }, {
          text: 'All',
          value: "ALL"
        }],
        model: null
      },
      sortFieldMap: {
        'username': 'access.identification',
        'lockedString': 'isLocked'
      }
    },
    users: []
  }),
  methods: {
    clickRow ({ id }) {
      window.open(`${window.location.origin}/#/users/${id}`, '_blank', "", false)
    },
    async newUser () {
      await this.$router.push('/users/-1')
    },
    async search () {
      this.$refs.mainDataTableRef.updateRouterQueries({
        page: 1,
        size: this.config.pagination.size
      })
      await this.fetchUsers()
    },
    async fetchUsers() {
      // state
      this.config.search.loading = true
      const response = await userService.listUsers({ 
          page: this.config.pagination.page - 1,
          size: this.config.pagination.size,
          sort: this.config.pagination.sort
        }, { 
          keyWord: this.config.search.criteria,
          lockedType: this.config.locked.model
        }
      )
      if (response.status === 200) {
        this.users = response.data.content
        this.config.pagination.totalPages = response.data.totalPages
        this.config.pagination.totalElements = response.data.totalElements
        // eslint-disable-next-line no-return-assign
        this.users.forEach(u => u.lockedString = u.locked ? 'Locked' : 'Unlocked')
      }
      this.config.search.loading = false
    },
    onSort(sort) {
      this.config.pagination.sort = !!sort ? sort : null
      this.fetchUsers()
    }
  },
  async mounted() {
    //set All "locked/unlocked" by default.
    this.config.locked.model = this.config.locked.types[2].value
    if (!this.config.pagination.urlQueries) await this.fetchUsers()
  }
}
</script>
<style scoped>
.full-height {
  height: 100%;
}

.row-pointer :hover {
  cursor: pointer;
}
</style>
